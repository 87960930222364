var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.t('Gateways')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",class:_vm.showFilter ? 'v-item--active v-btn--active elevation-0' : '',attrs:{"icon":""},on:{"click":function($event){_vm.showFilter = !_vm.showFilter}}},on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.t('Filters')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.showColumnDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-view-column")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.t('SelectColumns')))])]),(_vm.AppConsts.auth.isGranted('Permissions_Gateway_Create'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-4",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.edit()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3455052910)},[_c('span',[_vm._v(_vm._s(_vm.t('NewGateway')))])]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('v-data-table-filter',{attrs:{"headers":_vm.headers,"show-filter":_vm.showFilter},on:{"filterChanged":_vm.getItems}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.AppConsts.auth.isGranted('Permissions_Gateway_Update'))?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.edit(item.id, item.protocolName)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e(),(_vm.AppConsts.auth.isGranted('Permissions_Gateway_Delete'))?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"align-end":"","icon":"","small":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',{class:hover ? 'red--text' : ''},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)}):_vm._e()]}}])}),_c('edit-create-gateway-dialog',{attrs:{"show":_vm.showDialog,"id":_vm.id,"protocolName":_vm.protocolName},on:{"completed":_vm.completed}}),(_vm.availableColumnsAreSet)?_c('column-select-dialog',{attrs:{"show":_vm.showColumnDialog,"columns":_vm.headers,"available-columns":_vm.availableHeaders},on:{"completed":_vm.columnSelectCompleted}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }