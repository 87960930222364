import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { LoRaGateway, CreateLoRaGateway, Gateway } from '../../shared/models/dto/gateway';
import { VendorDevice } from '../../shared/models/dto/vendor'
import { CommunicationProtocol } from '../../shared/models/dto/communication-protocol';
import { SupportedVendor } from '../../shared/models/dto/vendor';
import { NameValue } from '../../shared/models/dto/general';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class EditCreateGatewayDialogComponent extends AuthComponentBase {
    @Prop({ default: null }) readonly id: string;
    @Prop({ default: false }) readonly show: boolean;
    @Prop({ default: '' }) readonly protocolName: string;

    refs = this.$refs as any;
    loading: boolean = true;
    valid: boolean = true;
    isEdit: boolean = false;
    formTitle: string = '';
    communicationProtocols: CommunicationProtocol[] = [];
    supportedVendors: SupportedVendor[] = [];
    vendorDevices: VendorDevice[] = [];
    errors: NameValue[] = [];
    vendorDevicesDisabled: Boolean = true;

    gateway: LoRaGateway = {
        id: null,
        name: null,
        description: null,
        vendorDeviceId: null,
        vendorName: null,
        vendorId: null,
        vendorDeviceName: null,
        protocolDisplayName: null,
        protocolName: null,
        gatewayId: null,
        isDeleted: false,
    };

    @Watch('show', { immediate: true })
    async onShowChanged() {
        if (this.show) {
            this.vendorDevicesDisabled = true;

            this.errors = [];

            this.gateway = {
                id: null,
                name: null,
                description: null,
                vendorDeviceId: null,
                vendorName: null,
                vendorId: null,
                vendorDeviceName: null,
                protocolDisplayName: null,
                protocolName: null,
                gatewayId: null,
                isDeleted: false,
            };

            this.loading = true;
            this.isEdit = !!this.id;
            this.formTitle = `${this.isEdit ? this.t('Edit') : this.t('New')} ${this.t('Gateway').toLowerCase()}`;

            this.communicationProtocols = await this.authService.getList('/api/communicationprotocols');
            this.supportedVendors = await this.authService.getList('/api/gateways/supportedVendors');

            if (this.isEdit) {
                await this.getGateway();
            }
            
            this.loading = false;
        }
    }

    async getGateway() {
        this.authService.get<LoRaGateway>(
            `/api/${this.protocolName}gateways/${this.id}`).then(async (response) => {
                this.gateway = response.content;
                
                if (this.gateway.vendorId) {
                    await this.onSuppliedVendorChange();
                }               
        });
    }

    async onSuppliedVendorChange() {
        this.vendorDevices = await this.authService.getList(`/api/supportedvendors/${this.gateway.vendorId}/vendordevices?$filter=devicetypeid eq 1`);
        this.vendorDevicesDisabled = false;
    }

    save() {
        if (this.refs.form.validate()) {
            this.errors = [];

            // TODO: discriminate between protocols
            const gateway: CreateLoRaGateway = {
                companyId: 1,
                name: this.gateway.name,
                description: this.gateway.description,
                gatewayId: this.gateway.gatewayId.replace(/[^A-Za-z0-9]/g, ''),
                vendorDeviceId: this.gateway.vendorDeviceId
            };

            const key: string = this.gateway.id ? this.gateway.id.toString() : '';

            this.authService.postOrPut<void>(
                `/api/${this.gateway.protocolName}gateways/${key}`, 
                gateway,
                key
            ).then((response) => {
                if (!response.isError) {
                    this.swalToast(2000, 'success', this.t('Successful'));
                    this.$emit('completed', true);
                } else {
                    response.errors.forEach(e => this.errors.push(e));
                }
            });
        }
    }

    close() {
        this.$emit('completed', false);
    }
}